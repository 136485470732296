import { H1, H2, H3 } from "./Typography";

const Body = () => {
  return (
    <div
      style={{
        paddingRight: "10%",
        paddingLeft: "10%",
        flex: 1,
        justifyContent: "center",
        marginBottom: 24,
      }}
    >
      <H1 text="Get notified of our next game launches!" />
      <H2 text="Jeju's Fantasy is available now!" />

      <H3 text="Enter your email address to get notified of any major sales!" />
    </div>
  );
};

export default Body;
