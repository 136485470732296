import MailchimpSubscribe from "react-mailchimp-subscribe";
import Form from "./Form";

const postUrl = `https://raycastingstudio.us20.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

const MailchimpForm = () => {
  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => (
        <Form
          subscribe={(data) => subscribe(data)}
          message={message}
          status={status}
        />
      )}
    />
  );
};

export default MailchimpForm;
