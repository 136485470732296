import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "./Link";

interface IconProps {
  icon: IconProp;
  url: string;
}

const Icon = ({ icon, url }: IconProps) => {
  return (
    <Link url={url}>
      <FontAwesomeIcon
        style={{ paddingLeft: 12, paddingRight: 12 }}
        size="2x"
        color="white"
        icon={icon}
      />
    </Link>
  );
};

export default Icon;
