import { ReactElement } from "react";

interface LinkProps {
  children: ReactElement;
  url: string;
}

const Link = ({ url, children }: LinkProps) => {
  return (
    <div onClick={() => window.open(url, "_blank", "noreferrer")}>
      {children}
    </div>
  );
};

export default Link;
