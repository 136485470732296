import { P1 } from "./Typography";

interface ButtonProps {
  onClick: () => void;
}

const Button = ({ onClick }: ButtonProps) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: 50,
        width: 100,
        border: "1px solid rgba(0, 0, 0, 1.5)",
        backgroundColor: "white",
        paddingLeft: 20,
        paddingRight: 20,
      }}
      onClick={onClick}
    >
      <P1 color="black" text="Submit" />
    </div>
  );
};

export default Button;
