import {
  faTwitter,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon";

const Header = () => {
  return (
    <div
      style={{
        padding: 150,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
      }}
    >
      <Icon url="https://instagram.com/raycasting.studio" icon={faInstagram} />
      <Icon url="https://twitter.com/raycasting" icon={faTwitter} />
      <Icon
        url="https://www.facebook.com/raycasting.studio/"
        icon={faFacebook}
      />
      <Icon url="mailto:raycasting.studio@gmail.com" icon={faEnvelope} />
    </div>
  );
};

export default Header;
