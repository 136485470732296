import { useForm } from "react-hook-form";
import Button from "./Button";
import Input from "./Input";
import Lottie from "lottie-react";
import checkMarkAnimation from "../Assets/checkMarkAnimated.json";
import errorAnimation from "../Assets/errorAnimation.json";
import loadingAnimation from "../Assets/loadingAnimated.json";
import { useState } from "react";

interface RaycastingStudioCustomFormProps {
  EMAIL: string;
  MERGE1: string;
  MERGE2: string;
}

type MailchimpSubmissionStatus = "success" | "error" | "sending" | null;

interface FormProps {
  status: MailchimpSubmissionStatus;
  message: string | Error | null;
  subscribe: (data: RaycastingStudioCustomFormProps) => void;
}

const Form = ({ status, subscribe }: FormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm();
  const onSubmit = () => {
    subscribe({
      EMAIL: watch("email"),
      MERGE1: watch("firstName"),
      MERGE2: watch("lastName"),
    });
  };

  return (
    <form
      style={{
        display: "flex",
      }}
    >
      <Input
        control={control}
        errors={errors}
        register={register}
        registerName="firstName"
        placehodler="First name"
      />
      <Input
        control={control}
        errors={errors}
        register={register}
        registerName="lastName"
        placehodler="Last name"
      />
      <Input
        control={control}
        errors={errors}
        register={register}
        registerName="email"
        placehodler="Email"
      />
      <Button onClick={handleSubmit(onSubmit)} />
      <Animation status={status} />
    </form>
  );
};

interface AnimationProps {
  status: MailchimpSubmissionStatus;
}

const Animation = ({ status }: AnimationProps) => {
  const [hasPlayedSuccessAnimation, setHasPlayedSuccessAnimation] =
    useState(false);
  const [hasPlayedErrorAnimation, setHasPlayedErrorAnimation] = useState(false);
  console.log(hasPlayedSuccessAnimation);
  switch (status) {
    case "success":
      return !hasPlayedSuccessAnimation ? (
        <Lottie
          style={{ maxWidth: 100, maxHeight: 50 }}
          animationData={checkMarkAnimation}
          loop={false}
          onComplete={() => setHasPlayedSuccessAnimation(true)}
        />
      ) : (
        <EmptyAnimation />
      );
    case "error":
      return !hasPlayedErrorAnimation ? (
        <Lottie
          style={{ maxWidth: 100, maxHeight: 50 }}
          animationData={errorAnimation}
          loop={false}
          onComplete={() => setHasPlayedErrorAnimation(true)}
        />
      ) : (
        <EmptyAnimation />
      );
    case "sending":
      return (
        <Lottie
          style={{ maxWidth: 100, maxHeight: 50 }}
          animationData={loadingAnimation}
          loop={true}
        />
      );

    default:
      return <EmptyAnimation />;
  }
};

const EmptyAnimation = () => (
  <div style={{ width: 100, maxWidth: 100, maxHeight: 50 }} />
);

export default Form;
