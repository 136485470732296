interface H1Props {
  text: string;
  color?: "black" | "white" | "grey";
}

export const H1 = ({ text, color = "white" }: H1Props) => {
  return <p style={{ fontSize: 60, textAlign: "center", color }}>{text}</p>;
};

export const H2 = ({ text, color = "white" }: H1Props) => {
  return <p style={{ fontSize: 40, textAlign: "center", color }}>{text}</p>;
};

export const H3 = ({ text, color = "white" }: H1Props) => {
  return <p style={{ fontSize: 20, textAlign: "center", color }}>{text}</p>;
};

export const P1 = ({ text, color = "white" }: H1Props) => {
  return <div style={{ fontSize: 18, textAlign: "center", color }}>{text}</div>;
};
