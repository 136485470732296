import Header from "./Components/Header";
import Body from "./Components/Body";
import Background from "./Components/Background";
import MailchimpForm from "./Components/MailchimpForm";

const App = () => {
  return (
    <Background>
      <div>
        <Header />

        <div
          className="App"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Body />
          <MailchimpForm />
        </div>
      </div>
    </Background>
  );
};

export default App;
