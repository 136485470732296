import { ReactElement } from "react";
import BackgroundImage from "./background.png";

interface BackgroundProps {
  children: ReactElement;
}

const Background = ({ children }: BackgroundProps) => {
  const containerStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    height: "100vh",
  };

  return <div style={containerStyle}>{children}</div>;
};

export default Background;
