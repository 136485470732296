import {
  UseFormRegister,
  FieldValues,
  FieldErrors,
  Controller,
  Control,
} from "react-hook-form";
import { P1 } from "./Typography";

interface InputProps {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  control: Control;
  registerName: string;
  placehodler: string;
}

const Input = ({
  register,
  errors,
  registerName,
  placehodler,
  control,
}: InputProps) => {
  return (
    <Controller
      name={registerName}
      control={control}
      defaultValue=""
      render={() => (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingRight: 18,
          }}
        >
          <input
            style={{ height: 50, width: 200 }}
            {...register(registerName, { required: true })}
            placeholder={placehodler}
          />
          {errors[registerName] && <P1 text="This field is required" />}
        </div>
      )}
    />
  );
};

export default Input;
